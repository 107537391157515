import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getProductCategories, deleteProductCategory, patchProductCategory, createProductCategory, searchProducts } from '../api/api'; // Remove unused imports
import { Modal, Button } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';
import LayoutPageHeader from '../components/common/LayoutPageHeader';
import ButtonCreate from '../components/common/ButtonCreate';

const CategoryProducts = () => {
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showModal, setShowModal] = useState(false);
  const [productCategoryToDelete, setProductCategoryToDelete] = useState(null);
  const [productNameToDelete, setProductNameToDelete] = useState('');
  const [categoryNameToDelete, setCategoryNameToDelete] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [newProductId, setNewProductId] = useState('');
  const [newSortOrder, setNewSortOrder] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const { t } = useTranslation();

  const page = parseInt(searchParams.get('page')) || 1;
  const limit = parseInt(searchParams.get('limit')) || 20;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getProductCategories(limit, page, 'sort_order', id);
        setProducts(data.results);
        setTotalCount(data.count);
        if (data.results.length > 0) {
          setCategoryName(data.results[0].category.name);
          setNewSortOrder(getInitialSortOrder(data.results));
        }
      } catch (err) {
        setError('Failed to load products');
        console.error(err);
      }
    };

    fetchProducts();
  }, [id, page, limit]);

  const loadOptions = async (inputValue) => {
    if (inputValue.length > 2) {
      try {
        const data = await searchProducts(inputValue);
        return data.results.map((product) => ({
          value: product.id,
          label: product.name,
        }));
      } catch (err) {
        setError('Failed to search products');
        console.error(err);
        return [];
      }
    }
    return [];
  };

  const totalPages = Math.ceil(totalCount / limit);

  const updateQueryParams = (newPage, newLimit) => {
    setSearchParams({ page: newPage, limit: newLimit });
  };

  const handleDeleteProductCategory = async (id) => {
    try {
      await deleteProductCategory(id);
      setProducts((prev) => prev.filter((productCategory) => productCategory.id !== id));
    } catch (err) {
      setError('Failed to delete product category');
    }
  };

  const handleDeleteClick = (id, productName, categoryName) => {
    setProductCategoryToDelete(id);
    setProductNameToDelete(productName);
    setCategoryNameToDelete(categoryName);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    if (productCategoryToDelete) {
      await handleDeleteProductCategory(productCategoryToDelete);
      setShowModal(false);
      setProductCategoryToDelete(null);
      setProductNameToDelete('');
      setCategoryNameToDelete('');
    }
  };

  const updateSortOrder = async (id, newSortOrder) => {
    try {
      await patchProductCategory(id, { sort_order: newSortOrder });
      setProducts((prev) => {
        const updatedProducts = prev.map((productCategory) =>
          productCategory.id === id
            ? { ...productCategory, sort_order: newSortOrder }
            : productCategory
        );
        return updatedProducts.sort((a, b) => a.sort_order - b.sort_order);
      });
    } catch (err) {
      setError('Failed to update sort order');
    }
  };

  const handleAddProductCategory = async () => {
    try {
      const newProductCategory = {
        product: newProductId,
        category: id,
        sort_order: newSortOrder,
      };
      await createProductCategory(newProductCategory);
      const data = await getProductCategories(limit, page, 'sort_order', id);
      setProducts(data.results);
      setTotalCount(data.count);
      setShowAddModal(false);
      setNewProductId('');
      setNewSortOrder(getInitialSortOrder(data.results));
    } catch (err) {
      setError('Failed to add product to category');
    }
  };

  const getInitialSortOrder = (products) => {
    if (products.length === 0) return 1;
    const maxSortOrder = Math.max(...products.map((product) => product.sort_order));
    return maxSortOrder + 1;
  };

  return (
    <div className="container py-4">

      <LayoutPageHeader
        title={t('products_for') + " " + categoryName}
        buttonElement={
          <ButtonCreate
            text={t('add_product')}
            onClick={() => setShowAddModal(true)}
          />
        }
      />
        
      {error && <div className="alert alert-danger">{error}</div>}
      
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <label htmlFor="limit" className="form-label me-2">
            {t('items_per_page')}:
          </label>
          <select
            id="limit"
            className="form-select d-inline-block w-auto"
            value={limit}
            onChange={(e) => {
              const newLimit = parseInt(e.target.value, 10);
              updateQueryParams(1, newLimit);
            }}
          >
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        <div>
          <span>{t('total_items')}: {totalCount}</span>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-hover table-sm">
          <thead>
            <tr>
              <th>{t('product_name')}</th>
              <th>{t('sort_order')}</th>
              <th>{t('actions')}</th>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            {products.map((productCategory, index) => (
              <tr key={productCategory.id}>
                <td>{productCategory.product.name}</td>
                <td>
                  <div className="d-flex align-items-center">
                    <button
                      className="btn btn-sm btn-outline-secondary me-1"
                      onClick={() =>
                        updateSortOrder(
                          productCategory.id,
                          productCategory.sort_order - 1
                        )
                      }
                      disabled={index === 0}
                    >
                      ↑
                    </button>
                    {productCategory.sort_order}
                    <button
                      className="btn btn-sm btn-outline-secondary ms-1"
                      onClick={() =>
                        updateSortOrder(
                          productCategory.id,
                          productCategory.sort_order + 1
                        )
                      }
                      disabled={index === products.length - 1}
                    >
                      ↓
                    </button>
                  </div>
                </td>
                <td>
                  <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteClick(productCategory.id, productCategory.product.name, productCategory.category.name);
                    }}
                  >
                    {t('delete')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between align-items-center mt-3">
        <button
          className="btn btn-secondary btn-sm"
          disabled={page === 1}
          onClick={() => updateQueryParams(page - 1, limit)}
        >
          {t('previous')}
        </button>
        <span>
          {t('page')} {page} {t('of')} {totalPages}
        </span>
        <button
          className="btn btn-secondary btn-sm"
          disabled={page === totalPages}
          onClick={() => updateQueryParams(page + 1, limit)}
        >
          {t('next')}
        </button>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('confirm_deletion')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('delete_confirmation', { productName: productNameToDelete, categoryName: categoryNameToDelete })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t('cancel')}
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            {t('delete')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('add_product_to_category')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label htmlFor="product" className="form-label">{t('product')}</label>
            <AsyncSelect
              cacheOptions
              loadOptions={loadOptions}
              defaultOptions
              onChange={(selectedOption) => setNewProductId(selectedOption.value)}
              placeholder={t('search_for_product')}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="sortOrder" className="form-label">{t('sort_order')}</label>
            <input
              type="number"
              id="sortOrder"
              className="form-control"
              value={newSortOrder}
              onChange={(e) => setNewSortOrder(parseInt(e.target.value, 10))}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddModal(false)}>
            {t('cancel')}
          </Button>
          <Button variant="primary" onClick={handleAddProductCategory}>
            {t('add')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CategoryProducts;