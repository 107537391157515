import React, { useState, useRef } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { useTranslation } from "react-i18next";

import "react-image-crop/dist/ReactCrop.css";

const ImageUploadWithPreview = ({ onImageUpload, initialImage }) => {
  const initialImageURL = initialImage;
  const [selectedImage, setSelectedImage] = useState(null);
  const [crop, setCrop] = useState(null); // Dynamic crop
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageFormat, setImageFormat] = useState(null); // Save the image format
  const [imageName, setImageName] = useState(null); // Save the image name
  const imageRef = useRef(null);
  const { t } = useTranslation();

  // Handle file selection and preview generation
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();

      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        setImageFormat(file.type || `image/${fileExtension}`); // Save the image format
        setImageName(file.name); // Save the image name
      };
      reader.readAsDataURL(file);
    }
  };

  // Remove selected image
  const removeImage = () => {
    setSelectedImage(null);
    setCroppedImage(null);
    onImageUpload(null); // Clear the photo in the parent component
  };

  // Handle image load and set default crop
  const handleImageLoad = (e) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

    const defaultCrop = centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        1, // 1:1 aspect ratio
        width,
        height
      ),
      width,
      height
    );

    setCrop(defaultCrop);
  };

  // Handle crop completion
  const handleCropComplete = async (crop) => {
    if (!crop || !imageRef.current) return;

    const canvas = document.createElement("canvas");
    const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
    const scaleY = imageRef.current.naturalHeight / imageRef.current.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      imageRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const croppedImageData = await new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const croppedFile = new File([blob], imageName, { type: imageFormat || 'image/jpeg' });
          resolve(URL.createObjectURL(croppedFile));
          onImageUpload(croppedFile); // Pass the cropped image file to the parent component
        }
      }, imageFormat || 'image/jpeg'); // Use the saved image format or default to 'image/jpeg'
    });

    setCroppedImage(croppedImageData);
  };

  return (
    <div className="container mt-4">
      <div className="card p-4">
        <h4 className="card-title text-center mb-3">{t("upload_and_preview_image")}</h4>

        {/* File Input */}
        <div className="mb-3">
          <input
            type="file"
            className="form-control"
            accept="image/*"
            onChange={handleImageChange}
            aria-label={t("choose_file")}
            placeholder={t("choose_file")}
          />
        </div>

        {/* Show initial image if it exists */}
        {initialImage && !selectedImage && (
          <div className="text-center">
            <img
              src={initialImageURL}
              alt={t("preview")}
              className="img-thumbnail"
              style={{ maxWidth: "100%", maxHeight: "500px" }}
            />

            <div className="text-center mt-3">
              <button className="btn app--button app--button_red" onClick={removeImage}>
                {t("remove")}
              </button>
            </div>
          </div>
        )}

        {/* Image Crop */}
        {selectedImage && (
          <div>
            <ReactCrop
              src={selectedImage}
              crop={crop}
              aspect={1} // 1:1 aspect ratio
              onChange={(newCrop) => setCrop(newCrop)}
              onComplete={(c) => {
                handleCropComplete(c);
              }}
            >
              <img
                ref={imageRef}
                src={selectedImage}
                alt={t("preview")}
                style={{ maxWidth: "100%" }}
                onLoad={handleImageLoad}
              />
            </ReactCrop>
            <div className="text-center mt-3">
              <button className="btn app--button app--button_red" onClick={removeImage}>
                {t("remove")}
              </button>
            </div>
          </div>
        )}

        {/* Cropped Image Preview */}
        {croppedImage && (
          <div className="text-center mt-3">
            <img
              src={croppedImage}
              alt={t("cropped_preview")}
              className="img-thumbnail"
              style={{ maxWidth: "100%", maxHeight: "300px" }}
            />
          </div>
        )}

        
      </div>
    </div>
  );
};

export default ImageUploadWithPreview;
