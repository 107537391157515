import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthContext'; // Import useAuth

import { verifyEmail as verifyEmailApi } from '../api/api';
import LanguageSwitcher from '../components/LanguageSwitcher';

import '../styles/Login.css';

function VerifyEmail() {
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { login } = useAuth(); // Use login from AuthContext

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const emailParam = queryParams.get('email');
    if (emailParam) {
      setEmail(decodeURIComponent(emailParam));
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const response = await verifyEmailApi({ code, email });
      login(response.token); // Use login method to store the token
      navigate('/'); // Redirect to dashboard or login page after successful verification
    } catch (error) {
      setError(error);
      
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-background">
      <main className="form-signin w-100 m-auto">
        <form onSubmit={handleSubmit}>
          <div className="text-center mb-4">
            <img
              src="/images/logo-black-h60.png"
              alt="Logo"
              className="mb-4"
              style={{ height: '60px', width: 'auto' }}
            />
          </div>
          <h1 className="h3 mb-3 fw-normal">{t('verify_email')}</h1>

          {/* Error message */}
          {errors && errors.non_field_errors && (
            <div className="alert alert-danger" role="alert" aria-live="assertive">
              {errors.non_field_errors.join(', ')}
            </div>
          )}

          {/* Error message */}
          {errors && errors.detail && (
            <div className="alert alert-danger" role="alert" aria-live="assertive">
              {errors.detail}
            </div>
          )}

          <div className="form-floating mb-3">
            <input
              type="text"
              className={`form-control app--pin-code ${errors?.code ? 'is-invalid' : ''}`}
              id="floatingCode"
              placeholder={t('verification_code')}
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
              pattern="\d{6}"
              title="Please enter a 6-digit code"
            />
            <label htmlFor="floatingCode">{t('verification_code')}</label>
            {errors?.code && (
              <div className="invalid-feedback">
                {errors.code.join(', ')}
              </div>
            )}
          </div>

          <button
            className="btn app--button_login w-100 py-2"
            type="submit"
            disabled={loading}
          >
            {loading ? t('verifying') : t('verify')}
          </button>

          <p className="mt-5 mb-3 text-body-secondary">&copy; 2024</p>
        </form>
        <div className="d-flex justify-content-center mt-3">
          <LanguageSwitcher />
        </div>
      </main>
    </div>
  );
}

export default VerifyEmail;