import { getCurrentLanguage } from '../i18n';

const BASE_URL = 'https://justqrmenu-9b224502ea73.herokuapp.com';

const getToken = () => localStorage.getItem('token');

const handleUnauthorized = () => {
  // Handle 401 globally: clear token and redirect to login
  localStorage.removeItem('token');
  window.location.href = '/login';
};

const createHeaders = (includeAuth = false, isFormData = false) => {
  const headers = {
    'Accept-Language': getCurrentLanguage(),
  };

  if (!isFormData) {
    headers['Content-Type'] = 'application/json'; // Only set for JSON requests
  }

  if (includeAuth) {
    const token = getToken();
    if (token) {
      headers['Authorization'] = `Token ${token}`;
    }
  }

  return headers;
};

// Wrapper for fetch requests with global 401 handling
const apiRequest = async (url, { method = 'GET', body = null, includeAuth = false, isFormData = false } = {}) => {
  const options = {
    method,
    headers: createHeaders(includeAuth, isFormData),
  };

  if (body) {
    if (isFormData) {
      const formData = new FormData(); // Initialize FormData instance
      for (const key in body) {
        const value = body[key];
        if (Array.isArray(value)) {
          // If the value is an array, append each item individually
          value.forEach((item) => {
            formData.append(`${key}`, item);
          });
        } else {
          // Otherwise, append the value directly
          formData.append(key, value);
        }
      }
      options.body = formData; // Assign the FormData to `options.body`
    } else {
      options.body = JSON.stringify(body);
    }
  }

  try {
    const response = await fetch(`${BASE_URL}${url}`, options);

    if (response.status === 401) {
      handleUnauthorized();
      return null;
    }

    if (response.status >= 500 && response.status < 600) {
      // TODO: Show a toast notification or error message (localization)
      // Fix warning: Do not throw inside a finally block
      //throw {'non_field_errors': ['Server error. Please try again later.']};
      alert('Server error. Please try again later.');
      return null;
    }

    if (!response.ok) {
      const errorData = await response.json();
      throw errorData;
    }

    return response.status === 204 ? null : response.json();
  } catch (error) {

    console.error('API Error:', error);
    throw error;
  }
};

// Login function
export const login = (username, password) =>
  apiRequest('/api/v1/login/', {
    method: 'POST',
    body: { username, password },
  });

// Logout function
export const logout = () =>
  apiRequest('/api/v1/logout/', {
    method: 'POST',
    includeAuth: true,
  });

// Fetch user details
export const getUserDetails = () =>
  apiRequest('/api/v1/me/', {
    includeAuth: true,
  });

// Fetch products
export const getProducts = (limit = 1000, page = 1, ordering = '-pk', search = '') =>
  apiRequest(`/company-api/v1/products/?limit=${limit}&page=${page}&ordering=${ordering}`, {
    includeAuth: true,
  });

// Search products
export const searchProducts = (search) =>
  apiRequest(`/company-api/v1/products/?name=${search}`, {
    includeAuth: true,
  });

// Create product
export const createProduct = (productData) =>
  apiRequest('/company-api/v1/products/', {
    method: 'POST',
    body: productData,
    includeAuth: true,
    isFormData: true,
  });

// Get product by ID
export const getProduct = (id) =>
  apiRequest(`/company-api/v1/products/${id}/`, {
    includeAuth: true,
});

// Update product
export const updateProduct = (id, updatedData) => {
  const isFormData = updatedData.photo !== null;
  return apiRequest(`/company-api/v1/products/${id}/`, {
    method: 'PUT',
    body: updatedData,
    includeAuth: true,
    isFormData,
  });
};

// Delete product
export const deleteProduct = (id) =>
  apiRequest(`/company-api/v1/products/${id}/`, {
    method: 'DELETE',
    includeAuth: true,
  });

// Fetch ingredients
export const getIngredients = (limit = 1000, page = 1, ordering = '-pk') =>
  apiRequest(
    `/company-api/v1/ingredients/?limit=${limit}&page=${page}&ordering=${ordering}`,
    { includeAuth: true }
  );

// Create ingredient
export const createIngredient = (ingredientData) =>
  apiRequest('/company-api/v1/ingredients/', {
    method: 'POST',
    body: ingredientData,
    includeAuth: true,
  });

// Delete ingredient
export const deleteIngredient = (id) =>
  apiRequest(`/company-api/v1/ingredients/${id}/`, {
    method: 'DELETE',
    includeAuth: true,
  });

// Update ingredient
export const updateIngredient = (id, updatedData) =>
  apiRequest(`/company-api/v1/ingredients/${id}/`, {
    method: 'PUT',
    body: updatedData,
    includeAuth: true,
  });


// Fetch categories with pagination and ordering
export const getCategories = (limit = 1000, page = 1, ordering = 'sort_order') =>
  apiRequest(
    `/company-api/v1/categories/?limit=${limit}&page=${page}&ordering=${ordering}`,
    { includeAuth: true }
);

// Create category
export const createCategory = (categoryData) =>
  apiRequest('/company-api/v1/categories/', {
    method: 'POST',
    body: categoryData,
    includeAuth: true,
});

// Delete category
export const deleteCategory = (id) =>
  apiRequest(`/company-api/v1/categories/${id}/`, {
    method: 'DELETE',
    includeAuth: true,
});

// Update category
export const updateCategory = (id, updatedData) =>
  apiRequest(`/company-api/v1/categories/${id}/`, {
    method: 'PUT',
    body: updatedData,
    includeAuth: true,
});

export const getCategoryDetails = (id) =>
  apiRequest(`/company-api/v1/categories/${id}/`, {
    includeAuth: true,
});

// prodcut-category: /company-api/v1/product-categories/
export const getProductCategories = (limit = 1000, page = 1, ordering = 'sort_order', categoryId) =>
  apiRequest(`/company-api/v1/product-categories/?category__pk=${categoryId}&limit=${limit}&page=${page}&ordering=${ordering}`, {
    includeAuth: true,
  });

export const getProductCategoriesByProductId = (limit = 1000, page = 1, ordering = 'sort_order', productId) =>
  apiRequest(`/company-api/v1/product-categories/?product__pk=${productId}&limit=${limit}&page=${page}&ordering=${ordering}`, {
    includeAuth: true,
  });

export const createProductCategory = (productCategoryData) =>
  apiRequest('/company-api/v1/product-categories/', {
    method: 'POST',
    body: productCategoryData,
    includeAuth: true,
});

export const deleteProductCategory = (id) =>
  apiRequest(`/company-api/v1/product-categories/${id}/`, {
    method: 'DELETE',
    includeAuth: true,
});

export const updateProductCategory = (id, updatedData) =>
  apiRequest(`/company-api/v1/product-categories/${id}/`, {
    method: 'PUT',
    body: updatedData,
    includeAuth: true,
});

export const patchProductCategory = (id, updatedData) =>
  apiRequest(`/company-api/v1/product-categories/${id}/`, {
    method: 'PATCH',
    body: updatedData,
    includeAuth: true,
});

// Fetch menus /company-api/v1/menus/?limit=10&page=1
export const getMenus = (limit = 1000, page = 1, ordering = '-pk') =>
  apiRequest(
    `/company-api/v1/menus/?limit=${limit}&page=${page}&ordering=${ordering}`,
    { includeAuth: true }
);

// Create menu
export const createMenu = (menuData) =>
  apiRequest('/company-api/v1/menus/', {
    method: 'POST',
    body: menuData,
    includeAuth: true,
});

// Delete menu
export const deleteMenu = (id) =>
  apiRequest(`/company-api/v1/menus/${id}/`, {
    method: 'DELETE',
    includeAuth: true,
});

// Update menu
export const updateMenu = (id, updatedData) =>
  apiRequest(`/company-api/v1/menus/${id}/`, {
    method: 'PUT',
    body: updatedData,
    includeAuth: true,
});

// Get menu details
export const getMenuDetails = (id) =>
  apiRequest(`/company-api/v1/menus/${id}/`, {
    includeAuth: true,
});

// QR Codes / Links (/company-api/v1/qr-codes/)
export const getQRCodes = (limit = 1000, page = 1, ordering = '-pk') =>
  apiRequest(
    `/company-api/v1/qr-codes/?limit=${limit}&page=${page}&ordering=${ordering}`,
    { includeAuth: true }
);

// Create QR Code
export const createQRCode = (qrCodeData) =>
  apiRequest('/company-api/v1/qr-codes/', {
    method: 'POST',
    body: qrCodeData,
    includeAuth: true,
});

// Delete QR Code
export const deleteQRCode = (id) =>
  apiRequest(`/company-api/v1/qr-codes/${id}/`, {
    method: 'DELETE',
    includeAuth: true,
});

// Update QR Code
export const updateQRCode = (id, updatedData) =>
  apiRequest(`/company-api/v1/qr-codes/${id}/`, {
    method: 'PUT',
    body: updatedData,
    includeAuth: true,
});

// Get QR Code details
export const getQRCodeDetails = (id) =>
  apiRequest(`/company-api/v1/qr-codes/${id}/`, {
    includeAuth: true,
});

// Change password /api/v1/change-password/ (POST)
export const changePassword = (oldPassword, newPassword) =>
  apiRequest('/api/v1/change-password/', {
    method: 'POST',
    body: { old_password: oldPassword, new_password: newPassword },
    includeAuth: true,
});

// get company /api/v1/my-company/ (GET)
export const getCompany = async () => 
  apiRequest('/api/v1/my-company/', {
    includeAuth: true,
});


// update company /api/v1/my-company/ (PATCH)
/*
{
    "name": "Test Company 11",
    "address": "Lorem ipsum",
    "phone_number": "05322860475",
    "email": "admin@company.com",
    "website": "https://www.company.com",
    "currency_id": 1 
}
*/
export const updateCompany = (companyData) =>
  apiRequest('/api/v1/my-company/', {
    method: 'PATCH',
    body: companyData,
    includeAuth: true,
});

// [POST] /api/v1/register/
export const register = (registerData) =>
  apiRequest('/api/v1/register/', {
    method: 'POST',
    body: registerData, 
});

// [POST] /api/v1/verify-email/
export const verifyEmail = async (verifyData) => {
  const response = await apiRequest('/api/v1/verify-email/', {
    method: 'POST',
    body: verifyData,
  });
  return response; // Return the response JSON
}; 

// [GET] /api/v1/currencies/
export const getCurrencies = () =>
  apiRequest('/api/v1/currencies/', {
    includeAuth: false,
});

// [GET] /api/v1/company-types/
export const getCompanyTypes = () =>
  apiRequest('/api/v1/company-types/', {
    includeAuth: false,
});

