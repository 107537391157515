import React, { useState } from 'react';
import { changePassword } from '../api/api';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LayoutPageHeader from '../components/common/LayoutPageHeader';

const ChangePassword = () => {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError({ non_field_errors: [t('new_passwords_do_not_match')] });
      return;
    }

    try {
      await changePassword(oldPassword, newPassword);
      setSuccess(t('password_changed_successfully'));
      setError(null);
      setTimeout(() => {
        navigate('/');
      }, 2000); // Redirect to dashboard after 2 seconds
    } catch (error) {
      setError(error);
      setSuccess(null);
    }
  };

  return (
    <div className="container py-4">
      
      <LayoutPageHeader title={t('change_password')} />

      {success && <div className="alert alert-success">{success}</div>}
      
      {/* Error message */}
      {errors && errors.non_field_errors && (
        <div className="alert alert-danger" role="alert" aria-live="assertive">
          {errors.non_field_errors.join(', ')}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="oldPassword" className="form-label">{t('old_password')}</label>
          <input
            type="password"
            className={`form-control ${errors?.old_password ? 'is-invalid' : ''}`}
            id="oldPassword"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            required
          />
          {errors?.old_password && (
            <div className="invalid-feedback">
              {errors.old_password.join(', ')}
            </div>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="newPassword" className="form-label">{t('new_password')}</label>
          <input
            type="password"
            className={`form-control ${errors?.new_password ? 'is-invalid' : ''}`}
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          {errors?.new_password && (
            <div className="invalid-feedback">
              {errors.new_password.join(', ')}
            </div>
          )}
        </div>
        <div className="mb-3">
          <label htmlFor="confirmPassword" className="form-label">{t('confirm_new_password')}</label>
          <input
            type="password"
            className="form-control"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn app--button app--button_blue">{t('change_password')}</button>
      </form>
    </div>
  );
};

export default ChangePassword;