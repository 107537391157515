// src/components/Sidebar.js

import React from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import { Link } from 'react-router-dom';
import { FaCarrot, FaHamburger, FaSourcetree, FaClipboardList, FaLink, FaBuilding } from 'react-icons/fa';

import '../styles/Sidebar.css';

const Sidebar = () => {
  const { t } = useTranslation();

  const menuItems = [
      { name: t('ingredients'), link: '/ingredients', icon: <FaCarrot /> }, // Malzemeler için daha uygun bir ikon
      { name: t('products'), link: '/products', icon: <FaHamburger /> },   // Ürünler için yiyecek ikonu
      { name: t('categories'), link: '/categories', icon: <FaSourcetree /> }, // Kategoriler için klasör ağacı ikonu
      { name: t('menus'), link: '/menus', icon: <FaClipboardList /> },       // Menüler için liste ikonu
      { name: t('links'), link: '/links', icon: <FaLink /> },           // Bağlantılar için ataş ikonu
      { name: t('company'), link: '/company', icon: <FaBuilding /> },        // Şirket için bina ikonu
  ];

  const closeSidebar = () => {
    // const offcanvas = Offcanvas.getInstance(document.querySelector('#sidebarMenu'));
    // offcanvas?.toggle();
    const closeSidebarButton = document.getElementById('closeSidebar');
    closeSidebarButton.click();
  }

  const handleLinkClick = () => {
    closeSidebar();
    //navigate(link);
  }

  return (
    <>
      {/* Offcanvas for mobile */}
      <div
        className="offcanvas offcanvas-start bg-dark text-white"
        tabIndex="-1"
        id="sidebarMenu"
        style={{ width: '250px' }}
      >
        <div className="offcanvas-header justify-content-center">
          <h5 className="offcanvas-title text-white" id="sidebarLabel">
            <Link to="/" className="d-flex align-items-center text-white text-decoration-none" onClick={handleLinkClick}>
              <img
                src="/images/logo-h40.png"
                alt="Logo"
                className="me-2"
                style={{ height: '40px', width: 'auto' }}
              />
            </Link>
          </h5>
          <button
            type="button"
            className="btn-close btn-close-white"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            id='closeSidebar'
          ></button>
        </div>
        <div className="offcanvas-body p-0">
          <ul className="nav nav-pills flex-column mb-auto">
            {menuItems.map((item, index) => (
              <li key={index} className="nav-item">
                <Link to={item.link} className="nav-link text-white d-flex align-items-center" onClick={handleLinkClick}>
                  {item.icon} {/* Use React Icon */}
                  <span className="ms-2">{item.name}</span>
                </Link>
              </li>
            ))}
            <li className="nav-item mt-auto">
              <hr className="text-white" />
              <div className="d-flex justify-content-center">
                <LanguageSwitcher />
              </div>
            </li>
          </ul>
        </div>
      </div>

      {/* Sidebar for desktop */}
      <div
        className="d-none d-md-flex flex-column flex-shrink-0 bg-dark text-white"
        style={{ width: '250px', minHeight: '100vh', position: 'fixed', top: 0, left: 0, overflowY: 'auto' }}
      >
        <Link
          to="/"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none p-3 justify-content-center"
        >
          <img
            src="/images/logo-h40.png"
            alt="Logo"
            className="me-2"
            style={{ height: '40px', width: 'auto' }}
          />

        </Link>
        <ul className="nav nav-pills flex-column mb-auto">
          {menuItems.map((item, index) => (
            <li key={index} className="nav-item">
              <Link to={item.link} className="nav-link text-white d-flex align-items-center">
                {item.icon} {/* Use React Icon */}
                <span className="ms-2">{item.name}</span>
              </Link>
            </li>
          ))}
          <li className="nav-item mt-auto">
            <hr className="text-white" />
            <div className="d-flex justify-content-center">
              <LanguageSwitcher />
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
