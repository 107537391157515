import React from 'react';
import { useTranslation } from 'react-i18next';

const LayoutPageHeader = ({ title, description, buttonElement }) => {
  const { t } = useTranslation();

  return (
    <>
    <div className="d-flex justify-content-between align-items-center">
      
        <h1 className="mb-0">{t(title)}</h1>
      
        {buttonElement && <div>{buttonElement}</div>}
      
    </div>
    {description && <p className="text-muted small mb-0">{description}</p>}
    <hr className='mb-4 mt-1' />
    </>
  );
};

export default LayoutPageHeader;
