import React, { useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { FaDownload } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const QRCodePreview = ({ qrData }) => {
  const { t } = useTranslation();
  const canvasRef = useRef(null);

  const downloadQRCode = () => {
    const canvas = canvasRef.current.querySelector('canvas');
    const url = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = url;
    link.download = 'qrcode.png';
    link.click();
  };

  return (
    <div className='text-center'>
      <div ref={canvasRef}>
        <QRCodeCanvas value={qrData} />
      </div>
      <small className='text-muted d-block'>
        <a className="link-underline-light" href={qrData} target="_blank" rel="noopener noreferrer">{qrData}</a>
      </small>
      <button className="btn btn-sm btn-outline-dark mt-3" onClick={downloadQRCode}>
        <FaDownload /> {t('download_qr_code')}
      </button>
    </div>
  );
};

export default QRCodePreview;
